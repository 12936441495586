
// import {WOW} from '../../../node_modules/wowjs/dist/wow';
// import './jquery.nicescroll.min'

// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     $('body').niceScroll({
//       mousescrollstep: 40,
//       scrollspeed: 80,
//       zindex: 101,
//       cursorcolor: '#BDBDBD',
//       cursorborder: '1px solid #BDBDBD',
//       cursorwidth: '5px',
//       cursoropacitymax: 0.6,
//       smoothscroll: true,
//       iframeautoresizeL: true,
//     });
//   }
// }
// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     setTimeout(() => {
//       $('body').getNiceScroll().resize()
//     }, 500);
//   }
// }

// new WOW().init();


// 内页banner导航效果
if($('.ny-banner').length > 0){
  //页面滚动监听
  $(document).scroll(function () {
      var scroH = $(document).scrollTop();
      $('.ny-banner .bg-img b').css('transform', 'translateY(' + scroH / 5 + 'px)');
  });
}

console.log($('.ny-banner .banner-nav .sub-nav').length)
if(window.screen.width > 1024 && $('.ny-banner').length > 0 && $('.ny-banner .banner-nav .sub-nav').length > 0){
  $('.ny-banner .banner-nav .nav-title').mouseenter(function () {
    $('.ny-banner .banner-nav').addClass('active')
  })
  $('.ny-banner .banner-nav').mouseleave(function () {
    $('.ny-banner .banner-nav').removeClass('active')
  })
}else if(window.screen.width <= 1024 && $('.ny-banner').length > 0){
  $('.ny-banner .banner-nav .nav-title').on('click',function (e) {
    e.stopPropagation();
    if($('.ny-banner .banner-nav').hasClass('active')){
      $('.ny-banner .banner-nav').removeClass('active')
      $('.ny-banner .banner-nav .sub-nav').slideUp(300)
    }else{
      $('.ny-banner .banner-nav').addClass('active')
      $('.ny-banner .banner-nav .sub-nav').slideDown(300)
    }
  })
  $('body').on('click',function (e) {
    console.log($(e.target).parents('.nav-title').length == 0)
    if($('.ny-banner .banner-nav').hasClass('active')){
      $('.ny-banner .banner-nav').removeClass('active')
      $('.ny-banner .banner-nav .sub-nav').slideUp(300)
    }
  })
}
